import React from "react";
import "./signup-button.scss";

type Props = {
  color?: string;
}

export const WaitlistButton = ({color}: Props) => {
  const showWidget = (e: React.MouseEvent) => {
    (window as unknown as { mjOpenPopin: Function }).mjOpenPopin(event, e.target);
  }

  return <a href="#" data-token="ae1cf357456d8bea4f430e86a9a68d5d" onClick={showWidget}
            className={`button button-signup ${color ? 'button-' + color : ''}`}>Join waitlist</a>
}
