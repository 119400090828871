import React, {useCallback, useEffect, useRef, useState} from "react";
import "./header.scss";
// @ts-ignore
import Logo from "../images/logo.inline.svg";
import {Link} from "gatsby";
import "../../../frontend-common/button.scss";
import {WaitlistButton} from "./waitlist-button";
// @ts-ignore
import Twitter from "../images/icons/twitter.inline.svg";
// @ts-ignore
import Facebook from "../images/icons/facebook.inline.svg";
// @ts-ignore
import LinkedIn from "../images/icons/linkedin.inline.svg";
import {isBrowser} from "../is-browser.helper";

export const Header = () => {

  const [headerBackgroundShown, setHeaderBackgroundShown] = useState(false);
  const headerBackgroundShownRef = useRef(false);

  const changeHeader = useCallback((e: MouseEvent) => {
    if (window.scrollY > 60 && !headerBackgroundShownRef.current) {
      headerBackgroundShownRef.current = true;
      setHeaderBackgroundShown(true);
    } else if (window.scrollY <= 60 && headerBackgroundShownRef.current) {
      headerBackgroundShownRef.current = false;
      setHeaderBackgroundShown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', changeHeader);

    setTimeout(() => {
      setHeaderBackgroundShown(window.scrollY > 60);
    }, 100);

    return () => {
      document.removeEventListener('scroll', changeHeader);
    }
  }, []);

  return <div className={`header-container ${headerBackgroundShown ? "scrolled" : ""}`}>
    <header className="header">
      <div className="left">
        <Link to="/">
          <Logo/>
        </Link>
        {/*<nav className="menu">*/}
        {/*  <Link to="/product" activeClassName="active">*/}
        {/*    Product*/}
        {/*  </Link>*/}
        {/*  <Link to="/pricing" activeClassName="active">*/}
        {/*    Pricing*/}
        {/*  </Link>*/}
        {/*  <Link to="/about-us" activeClassName="active">*/}
        {/*    About us*/}
        {/*  </Link>*/}
        {/*</nav>*/}
      </div>
      <div className="right">
        {/*<a href="/auth/login" className="button-tertiary">*/}
        {/*  Log in*/}
        {/*</a>*/}
        {/*<SignupButton/>*/}
        <div className="header-social">
          <div className="header-social-link">
            <a target="_blank" href="https://twitter.com/SkivvrNoCode">
              <Twitter/>
            </a>
          </div>
          <div className="header-social-link">
            <a target="_blank" href="https://www.facebook.com/Skivvr">
              <Facebook/>
            </a>
          </div>
          <div className="header-social-link">
            <a target="_blank" href="https://www.linkedin.com/company/skivvr">
              <LinkedIn/>
            </a>
          </div>
        </div>
        <WaitlistButton/>
      </div>
    </header>
  </div>;
}
