import React from "react";
import "./footer.scss";
// @ts-ignore
import LogoBlue from "../images/logo-blue.inline.svg";
// @ts-ignore
import Twitter from "../images/icons/twitter.inline.svg";
// @ts-ignore
import Facebook from "../images/icons/facebook.inline.svg";
// @ts-ignore
import LinkedIn from "../images/icons/linkedin.inline.svg";
import {Link} from "gatsby";

export const Footer = () => {
  return <div className="footer">
    <div className="footer-content">
      <LogoBlue/>

      <div className="footer-columns">
        <div className="footer-column">
          <div className="footer-column-header">
            Explore
          </div>
          <div className="footer-column-links">

            <div className="footer-column-link">
              <Link to="/privacy">
                Privacy
              </Link>
            </div>
            <div className="footer-column-link">
              <Link to="/cookie-policy" className="footer-column-link">
                Cookie Policy
              </Link>
            </div>
            <div className="footer-column-link">
              <Link to="/terms" className="footer-column-link">
                Terms
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-column footer-column-social">
          <div className="footer-column-social-link">
            <a target="_blank" href="https://twitter.com/SkivvrNoCode">
              <Twitter/>
            </a>
          </div>
          <div className="footer-column-social-link">
            <a target="_blank" href="https://www.facebook.com/Skivvr">
              <Facebook/>
            </a>
          </div>
          <div className="footer-column-social-link">
            <a target="_blank" href="https://www.linkedin.com/company/skivvr">
              <LinkedIn/>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>;
}
