import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Helmet} from "react-helmet";
import "../../../frontend-common/variables.scss";
import "./layout.scss";
import {Footer} from "./footer";

type IProps = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  className?: string;
}

export const Layout = ({children, title, className}: IProps) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return <main className={`layout ${className || ''}`}>
    <Helmet title={title || data.site.siteMetadata.title} defer={false}/>
    {children}
    <Footer/>
  </main>;
}
